/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Archeron Pro Bold by Mostardesign
 * URL: https://www.myfonts.com/products/bold-archeron-pro-225581
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

@font-face {
  font-family: "ArcheronPro-Bold";
  src: url("./font.woff2") format("woff2"), url("./font.woff") format("woff");
}
