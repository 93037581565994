/* Font Face */
@font-face {
  font-family: "Lexend";
  src: url("./Lexend-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Lexend";
  src: url("./Lexend-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lexend";
  src: url("./Lexend-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lexend";
  src: url("./Lexend-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lexend";
  src: url("./Lexend-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lexend";
  src: url("./Lexend-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lexend";
  src: url("./Lexend-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
