/* Font Face */
@font-face {
  font-family: "Kelson";
  src: url("./kelson-extrabold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Kelson";
  src: url("./kelson-sansregular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
